/* basic */
:root {
  --color_header: #7591f8;
  --color_box-shadow_headerfigure: #ffffff;
  --color_footer: #353535;
  --color_green: rgba(25, 101, 42, 0.9);
}
.wrapper {
  min-height: 100%;
  overflow: hidden;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
ul,
li,
p,
button,
a,
section {
  margin: 0px;
  padding: 0px;
}
h1 {
  font-size: 35px;
  font-weight: 500;
}
body {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
}
body._lock {
  overflow: hidden;
}
a {
  color: #fff;
  display: block;
}
.section-title {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 20px;
}
.price-cleaning__table {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 20px;
  border-collapse: collapse;
}
.price-cleaning__table tr td {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.price-cleaning__table caption {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
  caption-side: top;
  text-align: center;
  color: #000;
}
.price-cleaning__table thead {
  background-color: #e7e7e7;
}
.price-cleaning__table thead tr td:not(:first-child) {
  text-align: center;
}
.price-cleaning__table tbody tr td:not(:first-child) {
  text-align: center;
}
/* header =========================================================================================== header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color_header);
  z-index: 10;
}
.header__container {
  min-height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  background: url(../img/logo.png);
  background-size: cover;
  margin-left: 30px;
  margin-right: 120px;
  width: 60px;
  height: 60px;
  transition: 0.5s;
  z-index: 3;
}
.header__logo:hover {
  transform: scale(1.2);
}
.header__phone {
  display: none;
  z-index: 10;
  font-size: 24px;
  position: relative;
}
.header__phone a::before {
  position: absolute;
  left: -1.8em;
  content: '';
  width: 40px;
  height: 40px;
  background: url(../img/phone.png);
  background-size: cover;
}
.menu__icon {
  display: none;
  margin-right: 30px;
}
.menu__body {
  box-sizing: border-box;
}
.menu__list {
  list-style: none;
}
.menu__list > li {
  position: relative;
  margin: 0px 0px 0px 30px;
}
.menu__link {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}
.menu__link:hover {
  text-decoration: underline;
}
.menu__sub-list {
  position: absolute;
  top: 35px;
  left: 0px;
  background-color: var(--color_header);
  padding: 30px 15px 15px 15px;
  min-width: 200px;
  list-style: none;
}
.menu__sub-list li {
  margin: 0px 0px 30px 0px;
}
.menu__sub-list li a {
  color: #fff;
}
.menu__sub-list li:last-child {
  margin: 0px;
}
.menu__sub-link {
  color: #000;
  text-decoration: none;
}
.menu__sub-link:hover {
  text-decoration: underline;
}
.menu__arrow {
  display: none;
}
body._pc .menu__list > li:hover .menu__sub-list {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  pointer-events: all;
}
body._touch .menu__list > li {
  display: flex;
  align-items: center;
}
body._touch .menu__link {
  flex: 1 1 auto;
}
body._touch .menu__arrow {
  display: block;
  width: 0;
  height: 0;
  margin: 0px 0px 0px 5px;
  transition: transform 0.3s ease 0s;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid hsl(0, 0%, 100%);
}
body._touch .menu__list > li._active .menu__sub-list {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
  pointer-events: all;
}
body._touch .menu__list > li._active .menu__arrow {
  transform: rotate(-180deg);
}
.tocall {
  color: #fff;
  margin-right: 20px;
  line-height: 140%;
  text-align: right;
  font-size: 20px;
}
.tocall a {
  text-decoration: underline;
}
.main {
  margin-top: 80px;
}
@media screen and (max-width: 1000px) {
  .d-none-on-mobile {
    display: none;
  }
}
/* стили общие для всех главных экранов ======================================= стили общие для всех главных экранов */
.main-screen {
  min-height: 600px;
}
.main-screen__title {
  padding-top: 20px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  letter-spacing: 10px;
  line-height: 1.1;
}
.main-screen__offer {
  position: absolute;
  left: 12%;
}
.main-screen__offer-text > ul {
  list-style-type: none;
}
.main-screen__offer-text > ul > li {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
}
.main-screen__elipse {
  position: absolute;
  right: 12%;
}
.main-screen__elipse-wrap {
  border-radius: 50%;
}
.main-screen__elipse-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: -140px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #19652A;
  color: #fff;
  line-height: 32px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
}
.main-screen__elipse-circle:hover {
  color: #fff;
  text-decoration: none;
}
.main-screen__elipse-circle-sub-circle {
  position: absolute;
  top: 50px;
  left: -140px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--color_header);
}
/* main-screen ==================================================================================== main-screen */
.main-screen-home-page {
  display: grid;
  grid-template-columns: 60% 40%;
  position: relative;
}
.main-screen-home-page-title {
  position: absolute;
  top: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 40px;
  z-index: 8;
  text-align: center;
  line-height: 1.4;
  max-width: 700px;
}
.main-screen-left {
  position: relative;
}
.main-screen-left::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.main-screen-img {
  max-width: 100%;
}
.main-screen-left-text {
  position: absolute;
  left: 10%;
  top: 18%;
  max-width: 525px;
  z-index: 8;
}
.main-screen-left-text > ul {
  list-style-type: none;
}
.main-screen-left-text > ul > li {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  line-height: 40px;
}
.main-screen-left-text > ul > li:not(:first-child) {
  margin-top: 30px;
}
.main-screen-right {
  background: linear-gradient(to bottom, #183815, #406047);
}
.round-block {
  position: absolute;
  bottom: -20px;
  right: -120px;
  width: 300px;
  height: 300px;
  border: none;
  border-radius: 50%;
  background-color: var(--color_header);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.round-block > ul {
  list-style-type: none;
}
.round-block > ul > li {
  color: #fff;
  line-height: 1.9;
  font-size: 22px;
}
@media screen and (max-width: 1700px) {
  .main-screen-right-form {
    margin-top: 15%;
  }
  .round-block {
    width: 220px;
    height: 220px;
    right: -80px;
  }
  .round-block > ul > li {
    line-height: 1.5;
    font-size: 16px;
  }
}
@media screen and (max-width: 1400px) {
  .main-screen-home-page-title {
    font-size: 30px;
  }
  .main-screen-left-text {
    left: 15%;
  }
  .main-screen-left-text > ul > li {
    font-size: 28px;
  }
  .form1-group {
    margin-top: 20px;
  }
  .form-contact-you {
    text-align: center;
  }
  .form1-row .form-submit button {
    font-size: 20px;
  }
}
@media screen and (max-width: 1250px) {
  .header__logo {
    margin-right: 0px;
  }
  .main-screen-home-page-title {
    top: 10px;
    font-size: 26px;
  }
  .main-screen-left-text > ul > li {
    font-size: 26px;
    line-height: 1.3;
  }
  .main-screen-left-text > ul > li:not(:first-child) {
    margin-top: 20px;
  }
  .round-block {
    width: 180px;
    height: 180px;
    right: -60px;
  }
  .round-block > ul > li {
    line-height: 2.1;
    font-size: 14px;
  }
  .form1-group {
    margin-top: 15px;
  }
  .form1-row .form-submit button {
    font-size: 18px;
  }
  .form-contact-you {
    margin-top: 10px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1100px) {
  .menu__link {
    font-size: 16px;
  }
  .tocall * {
    font-size: 16px;
  }
  .menu__list > li {
    margin: 0 0 0 15px;
  }
  .main-screen-right-form {
    margin-top: 10%;
  }
  .main-screen-home-page {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .round-block {
    display: none;
  }
  .main-screen-home-page-title {
    font-size: 24px;
    line-height: 1.2;
    top: 5px;
  }
  .form1-group {
    margin-top: 8px;
  }
  .form1-row .form-submit button {
    font-size: 16px;
    height: 35px;
  }
  .form1-group .form-control {
    height: 35px;
  }
  .form-contact-you {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 10px;
  }
  .main-screen-left-text > ul > li {
    line-height: 1.2;
    font-size: 24px;
  }
  .main-screen-left-text > ul > li:not(:first-child) {
    margin-top: 12px;
  }
}
@media screen and (max-width: 1000px) {
  .main-screen-right-form {
    margin-top: 12%;
  }
  .main-screen-left-text {
    top: 15%;
  }
  .main-screen-img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .form1-group {
    margin-top: 0px;
  }
  .form1-row .form-submit {
    margin-top: 15px;
  }
  .main-screen-left-text > ul > li {
    font-size: 20px;
  }
  .form1-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 700px) {
  .main-screen-home-page {
    grid-template-columns: 1fr;
  }
  .main-screen-left {
    padding-right: 10px;
    padding-left: 10px;
    height: 300px;
    background-image: url(../img/header-foto.jpg);
    background-size: cover;
  }
  .main-screen-img {
    display: none;
  }
  .main-screen-left-text {
    position: relative;
    margin: 0 auto;
    top: 22%;
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 20px;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
  }
  .main-screen-right-form {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .main__description .main__description-description {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .header__phone a::before {
    width: 25px;
    height: 25px;
  }
  .header__phone {
    font-size: 20px;
  }
  .main-screen-home-page-title {
    font-size: 18px;
    font-weight: 500;
  }
  .main-screen-left-text {
    top: 18%;
  }
}
@media screen and (max-width: 375px) {
  .header__logo {
    width: 45px;
    height: 45px;
  }
  .header__phone a::before {
    width: 20px;
    height: 20px;
    left: -1.3em;
  }
  .header__phone {
    font-size: 18px;
  }
  .main-screen-left-text > ul > li {
    font-style: italic;
  }
  .main-screen-left-text > ul > li:not(:first-child) {
    margin-top: 8px;
  }
  .form1-row .form-submit button {
    font-size: 14px;
  }
  .form-contact-you {
    font-size: 12px;
  }
  .form2-title {
    font-size: 22px;
  }
  .form2-subtitle {
    font-size: 12px;
  }
  .form2-row {
    margin: 0 auto;
  }
  .form2-group {
    width: 100%;
  }
  label.custom-control-label {
    font-size: 12px;
  }
}
/* main-description ============================================================================== main-description */
.main__description {
  line-height: 1.6;
  text-align: justify;
  padding: 0px 10px 0px 10px;
  font-size: 20px;
}
.main__description-title {
  text-align: center;
  margin: 30px 0px 20px;
  font-size: 28px;
  font-weight: 500;
}
.main__description-title-hr {
  width: 40%;
  color: var(--color_header);
  height: 2px;
  background-color: var(--color_header);
}
.main__description-description::first-letter {
  float: left;
  line-height: 35px;
  font-size: 60px;
  font-family: Montserrat;
  color: var(--color_green);
  border: solid var(--color_green);
  padding: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.main__description-description ul {
  margin-top: 10px;
}
.main__description-description ul li {
  margin-bottom: 5px;
}
.main__description-description ul li:last-child {
  margin-bottom: 0;
}
.our-chemistry {
  font-size: 24px;
  text-align: center;
  padding: 20px 0px;
}
@media screen and (max-width: 1000px) {
  .main__description-description {
    font-size: 18px;
  }
  .our-chemistry {
    font-size: 22px;
  }
}
@media screen and (max-width: 375px) {
  .main__description-description {
    font-size: 16px;
  }
  .main__description-description::first-letter {
    font-size: 35px;
  }
  .our-chemistry {
    font-size: 20px;
  }
}
/* form смотреть в файле form.less ================================================= form смотреть в файле form.less */
/* блок cleaning-season =============================================================================== cleaning-season */
.cleaning-season {
  background: linear-gradient(to bottom, rgba(45, 22, 10, 0.9), rgba(87, 37, 9, 0.4)), url(../img/img2.jpg) no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}
.cleaning-season__wrap {
  display: flex;
  align-items: center;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
}
.cleaning-season__title {
  width: 50%;
  font-size: 38px;
  line-height: 1.6;
  font-weight: 400;
  padding: 10px;
}
.cleaning-season__title > div {
  max-width: 400px;
  margin: 0 auto;
}
.cleaning-season__description {
  width: 50%;
  line-height: 1.6;
  margin: 0 auto;
  padding: 20px;
}
.cleaning-season__description-title {
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 3px;
  text-align: center;
}
.cleaning-season__description-text {
  text-align: justify;
  margin-top: 20px;
  font-size: 20px;
}
@media screen and (max-width: 1000px) {
  .cleaning-season {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .cleaning-season__title > div {
    font-size: 30px;
  }
  .cleaning-season__description-title {
    font-size: 20px;
  }
  .cleaning-season__description-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 700px) {
  .cleaning-season__wrap {
    flex-direction: column;
  }
  .cleaning-season__title {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  .cleaning-season__title > div {
    width: 100%;
    text-align: center;
    max-width: 100%;
  }
  .cleaning-season__description {
    width: 100%;
  }
  .cleaning-season__description-text {
    margin-top: 10px;
  }
}
/* блок servises ============================================================================================== servises */
.servises {
  max-width: 1400px;
  margin: 0 auto;
}
.servises h2 {
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
}
.servises__wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0 20px;
}
.servises__item {
  position: relative;
  box-sizing: border-box;
  background-color: #f8f7f7;
  border-radius: 10px;
  overflow: hidden;
}
.servises__item:hover {
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.68);
  -moz-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  background-color: var(--color_header);
  color: #fff;
  transition: 0.5s;
}
.servises__item-img {
  height: 300px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-bottom: 20px;
}
.servises__item-img:hover {
  scale: 1.1;
  transition: 1s;
}
.servises__item-img._cottage {
  background: url(../img/cottage.jpg);
  background-size: cover;
}
.servises__item-img._repair {
  background: url(../img/repair.jpg);
  background-size: cover;
}
.servises__item-img._room {
  background: url(../img/room.jpg);
  background-size: cover;
}
.servises__item-img._window {
  background: url(../img/window.jpg);
  background-size: cover;
}
.servises__item-title {
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}
.servises__item-hr {
  width: 50%;
}
.servises__item-description {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 50px;
  font-size: 16px;
  padding: 15px;
}
.servises__item-btn {
  text-transform: uppercase;
  padding: 10px;
  background-color: var(--color_green);
  color: #fff;
  min-width: 160px;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 80px);
  cursor: pointer;
}
@media screen and (max-width: 1100px) {
  .servises__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) {
  .servises__wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* блок geo ========================================================================================================== geo */
.geo {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.geo__title {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  padding: 15px;
}
/* блок chemistry ============================================================================================ chemistry */
.chemistry {
  margin-top: 30px;
}
.chemistry__title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}
.chemistry__subtitle {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}
/* СТРАНИЦА "Уборка коттеджей" =========================================================== страница "Уборка коттеджей" */
/* блок main-screen =================================================================================================  */
.cleaning-cottage {
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(../img/main-screen-cottage-1.jpg) no-repeat;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}
.cleaning-cottage .main-screen__offer {
  position: absolute;
  top: 13%;
  max-width: 550px;
}
.cleaning-cottage .main-screen__offer-text > ul > li {
  line-height: 40px;
  font-style: italic;
}
.cleaning-cottage .main-screen__offer-text > ul > li:not(:first-child) {
  margin-top: 25px;
}
.cleaning-cottage .main-screen__elipse {
  top: 14%;
}
.cleaning-cottage .main-screen__elipse-wrap {
  position: relative;
  width: 550px;
  height: 470px;
  background: url(../img/cottage.jpg) center center;
  background-size: cover;
}
@media screen and (max-width: 1700px) {
  .cleaning-cottage .main-screen__offer {
    left: 8%;
  }
  .cleaning-cottage .main-screen__elipse {
    right: 8%;
  }
}
@media screen and (max-width: 1400px) {
  .cleaning-cottage .main-screen__offer {
    max-width: 500px;
  }
  .cleaning-cottage .main-screen__offer {
    left: 3%;
  }
  .cleaning-cottage .main-screen__elipse {
    right: 3%;
  }
  .cleaning-cottage .main-screen__elipse-wrap {
    width: 500px;
    height: 427px;
  }
  .cleaning-cottage .main-screen__elipse {
    top: 16%;
  }
  .cleaning-cottage .main-screen__elipse-circle {
    width: 180px;
    height: 180px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1250px) {
  .cleaning-cottage .main-screen__title {
    font-size: 35px;
  }
  .cleaning-cottage .main-screen__offer {
    top: 20%;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    line-height: 35px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    font-size: 30px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 15px;
  }
  .cleaning-cottage .main-screen__elipse-wrap {
    width: 450px;
    height: 385px;
  }
  .cleaning-cottage .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -100px;
  }
  .cleaning-cottage .main-screen__elipse-circle-sub-circle {
    left: -110px;
  }
}
@media screen and (max-width: 1000px) {
  .main-screen {
    min-height: 400px;
  }
  .cleaning-cottage .main-screen__offer {
    max-width: 400px;
  }
  .cleaning-cottage .main-screen__title {
    font-size: 30px;
  }
  .cleaning-cottage .main-screen__offer {
    top: 15%;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    font-size: 26px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    line-height: 30px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 10px;
  }
  .cleaning-cottage .main-screen__elipse-wrap {
    width: 300px;
    height: 256px;
  }
  .cleaning-cottage .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -110px;
  }
  .cleaning-cottage .main-screen__elipse-circle-sub-circle {
    left: -120px;
  }
}
@media screen and (max-width: 768px) {
  .cleaning-cottage .main-screen__offer-text > ul > li {
    font-size: 22px;
  }
  .cleaning-cottage .main-screen__offer {
    max-width: 300px;
  }
  .cleaning-cottage .main-screen__elipse-wrap {
    width: 250px;
    height: 250px;
  }
  .cleaning-cottage .main-screen__elipse {
    top: 20%;
  }
}
@media screen and (max-width: 700px) {
  .main-screen {
    min-height: auto;
  }
  .cleaning-cottage .main-screen__offer {
    position: relative;
    margin: 10px auto;
  }
  .cleaning-cottage .main-screen__offer {
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 20px;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
  }
  .cleaning-cottage .main-screen__elipse {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .cleaning-cottage .main-screen__elipse .main-screen__elipse-wrap {
    margin-left: 200px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 570px) {
  .cleaning-cottage .main-screen__title {
    font-size: 24px;
    padding-top: 5px;
  }
  .cleaning-cottage .main-screen__offer-text {
    margin-top: 10px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    font-size: 20px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 10px;
  }
  .cleaning-cottage .main-screen__offer-text > ul > li {
    line-height: 20px;
  }
}
/* блок с краткой информацией по уборке коттеджей ===================================================== */
.information-part {
  margin-top: 30px;
}
.information-part h1 {
  text-align: center;
  margin-bottom: 10px;
}
.information-part-wrap {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}
.information-part-wrap__img {
  width: 50%;
  padding: 0 20px 10px;
}
.information-part-wrap__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.information-part-wrap__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  text-align: justify;
  width: 50%;
  padding: 10px 20px 10px 5px;
}
@media screen and (max-width: 1250px) {
  .information-part-wrap__text {
    line-height: 1.4;
  }
}
@media screen and (max-width: 1100px) {
  .information-part-wrap__text {
    font-size: 16px;
  }
  .information-part .section-title {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .information-part-wrap {
    display: flex;
    flex-direction: column;
  }
  .information-part-wrap__img {
    width: 100%;
    height: 300px;
  }
  .information-part-wrap__text {
    width: 100%;
    padding-left: 20px;
  }
}
@media screen and (max-width: 480px) {
  .information-part-wrap__text {
    font-size: 14px;
  }
}
/* блок Профессиональный подход ================================================================================= */
.professionally {
  margin-top: 30px;
  padding: 0 20px 0 20px;
}
.professionally-wrap {
  max-width: 1440px;
  margin: 20px auto 0;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1.4fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "top1 top2 top3" "bottom bottom bottom";
}
.professionally-wrap > div {
  background-color: #F1F1F1;
  text-align: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}
.prof__who-is-doing {
  grid-area: top1;
}
.prof__how-many-people {
  grid-area: top2;
}
.prof__we-bring {
  grid-area: top3;
}
.prof__how-much-time {
  grid-area: bottom;
}
.prof__icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
}
.prof__icon img {
  width: 100%;
}
.prof__who-is-doing-title {
  font-size: 20px;
  font-weight: 500;
}
.prof__who-is-doing-description {
  margin-top: 10px;
  line-height: 1.3;
}
@media screen and (max-width: 1100px) {
  .professionally-wrap {
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "top1 top3" "top2 bottom";
  }
  .professionally .section-title {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .professionally-wrap {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "top1" "top2" "top3" "bottom";
  }
  .professionally .section-title {
    font-size: 24px;
  }
  .prof__who-is-doing-description {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .prof__icon {
    width: 40px;
    height: 40px;
  }
}
/* блок Какие работы входят в уборку  коттеджа ================================================================== */
.cleaning-work-list {
  margin-top: 30px;
  padding: 0 20px;
}
.cleaning-work-list__table {
  max-width: 1200px;
  margin: 20px auto 0;
  border-collapse: collapse;
}
.cleaning-work-list__table tr {
  border-bottom: solid 1px #000;
}
.cleaning-work-list__table tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.content-to-center {
  text-align: center;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 1100px) {
  .cleaning-work-list .section-title {
    font-size: 28px;
  }
  .cleaning-work-list__table tr td {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .cleaning-work-list .section-title {
    font-size: 24px;
  }
  .cleaning-work-list__table tr td {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .cleaning-work-list__table tr td {
    font-size: 12px;
  }
}
/* блок Стоимость уборки коттеджей ================================================================================ */
.price-cleaning-cottage {
  margin: 30px 20px;
}
@media screen and (max-width: 1100px) {
  .price-cleaning-cottage .section-title {
    font-size: 28px;
  }
  .price-cleaning-cottage .price-cleaning__table {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .price-cleaning-cottage .price-cleaning__table {
    font-size: 14px;
  }
}
@media screen and (max-width: 520px) {
  .price-cleaning-cottage {
    margin: 30px 5px;
  }
  .price-cleaning-cottage .price-cleaning__table {
    font-size: 10px;
  }
}
@media screen and (max-width: 480px) {
  .price-cleaning-cottage .price-cleaning__table {
    font-size: 8px;
  }
}
/* END СТРАНИЦА "Уборка коттеджей" ========================================== END страница "Уборка коттеджей" */
/* СТРАНИЦА "Уборка после ремонта" ==================================================== страница "Уборка после ремонта" */
/* блок main-screen */
.cleaning-after-repair {
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)), url(../img/main-screen-after-repair.jpg) no-repeat;
  background-size: cover;
}
.cleaning-after-repair .main-screen__title {
  max-width: 1200px;
  margin: 0 auto;
}
.cleaning-after-repair .main-screen__offer {
  position: absolute;
  top: 20%;
  max-width: 550px;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 20px;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
}
.cleaning-after-repair .main-screen__offer-text > ul > li {
  color: #fff;
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
  font-style: italic;
}
.cleaning-after-repair .main-screen__offer-text > ul > li:not(:first-child) {
  margin-top: 10px;
}
.cleaning-after-repair .main-screen__elipse {
  position: absolute;
  top: 18%;
}
.cleaning-after-repair .main-screen__elipse .main-screen__elipse-wrap {
  position: relative;
  width: 550px;
  height: 470px;
  background: url(../img/after-repair.jpg) center center;
  background-size: cover;
}
@media screen and (max-width: 1700px) {
  .cleaning-after-repair .main-screen__offer {
    left: 8%;
  }
  .cleaning-after-repair .main-screen__elipse {
    right: 8%;
  }
}
@media screen and (max-width: 1500px) {
  .cleaning-after-repair .main-screen__offer {
    left: 3%;
  }
  .cleaning-after-repair .main-screen__elipse {
    right: 3%;
  }
  .cleaning-after-repair .main-screen__elipse .main-screen__elipse-wrap {
    width: 500px;
    height: 427px;
  }
  .cleaning-after-repair .main-screen__elipse {
    top: 22%;
  }
  .cleaning-after-repair .main-screen__elipse-circle {
    width: 180px;
    height: 180px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1250px) {
  .cleaning-after-repair .main-screen__title {
    font-size: 35px;
  }
  .cleaning-after-repair .main-screen__offer {
    top: 20%;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li {
    line-height: 30px;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li {
    font-size: 28px;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 15px;
  }
  .cleaning-after-repair .main-screen__elipse-wrap {
    width: 450px;
    height: 385px;
  }
  .cleaning-after-repair .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -100px;
  }
  .cleaning-after-repair .main-screen__elipse-circle-sub-circle {
    left: -110px;
  }
}
@media screen and (max-width: 1100px) {
  .main-screen {
    min-height: 400px;
  }
  .cleaning-after-repair .main-screen__offer {
    max-width: 400px;
  }
  .cleaning-after-repair .main-screen__title {
    font-size: 28px;
    padding-top: 10px;
  }
  .cleaning-after-repair .main-screen__offer {
    top: 24%;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li {
    font-size: 20px;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li {
    line-height: 20px;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 6px;
  }
  .cleaning-after-repair .main-screen__elipse {
    top: 28%;
  }
  .cleaning-after-repair .main-screen__elipse .main-screen__elipse-wrap {
    width: 300px;
    height: 256px;
  }
  .cleaning-after-repair .main-screen__elipse .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -110px;
  }
  .cleaning-after-repair .main-screen__elipse-circle-sub-circle {
    left: -120px;
  }
}
@media screen and (max-width: 900px) {
  .cleaning-after-repair .main-screen__elipse .main-screen__elipse-wrap {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 768px) {
  .cleaning-after-repair .main-screen__title {
    font-size: 26px;
    letter-spacing: normal;
  }
  .cleaning-after-repair {
    padding-right: 10px;
    padding-left: 10px;
  }
  .cleaning-after-repair .main-screen__offer {
    position: relative;
    margin: 10px auto;
    left: 0%;
  }
  .cleaning-after-repair .main-screen__elipse {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .cleaning-after-repair .main-screen__elipse .main-screen__elipse-wrap {
    margin-left: 200px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 570px) {
  .cleaning-after-repair .main-screen__title {
    font-size: 20px;
  }
  .cleaning-after-repair .main-screen__offer-text > ul > li {
    font-size: 18px;
  }
}
/* блок с работами при Уборке после ремонта ================================================================= */
.work-list-after-repair {
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.work-list-after-repair-wrap {
  max-width: 800px;
  margin: 20px auto 0px;
}
.work-list-after-repair-wrap ul {
  list-style-image: url(../img/check-mark.png);
}
.work-list-after-repair-wrap ul li:not(:first-child) {
  margin-top: 8px;
}
@media screen and (max-width: 1100px) {
  .work-list-after-repair .section-title {
    font-size: 28px;
  }
  .work-list-after-repair-wrap ul li {
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  .work-list-after-repair {
    padding-left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .work-list-after-repair .section-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  .work-list-after-repair-wrap ul li {
    font-size: 14px;
  }
}
/* блок стоимость уборки после ремонта ============================================================ */
.price-cleaning-after-repair {
  margin-top: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
@media screen and (max-width: 1100px) {
  .price-cleaning-after-repair .section-title {
    font-size: 28px;
  }
  .price-cleaning-after-repair .price-cleaning__table tr td {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .price-cleaning-after-repair .section-title {
    font-size: 24px;
  }
  .price-cleaning-after-repair .price-cleaning__table caption {
    font-size: 18px;
  }
  .price-cleaning-after-repair .price-cleaning__table tr td {
    font-size: 14px;
  }
}
@media screen and (max-width: 520px) {
  .price-cleaning-after-repair .price-cleaning__table tr td {
    font-size: 10px;
  }
}
@media screen and (max-width: 480px) {
  .price-cleaning-after-repair {
    padding-right: 5px;
    padding-left: 5px;
  }
  .price-cleaning-after-repair .price-cleaning__table tr td {
    font-size: 8px;
  }
  .price-cleaning-after-repair {
    overflow-x: scroll;
  }
}
/* END СТРАНИЦА "Уборка после ремонта" =========================================== END СТРАНИЦА "Уборка после ремонта"  */
/* СТРАНИЦА "Уборка квартир" ============================================================ страница "Уборка квартир" */
.cleaning-apartment {
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url(../img/main-screen-apartment.jpg) no-repeat;
  background-size: cover;
}
.cleaning-apartment .main-screen__offer {
  position: absolute;
  top: 13%;
  max-width: 550px;
  min-height: 305px;
}
.cleaning-apartment .main-screen__offer-text > ul > li {
  line-height: 40px;
  font-style: italic;
}
.cleaning-apartment .main-screen__offer-text > ul > li:not(:first-child) {
  margin-top: 20px;
}
.cleaning-apartment .main-screen__elipse {
  top: 14%;
}
.cleaning-apartment .main-screen__elipse-wrap {
  position: relative;
  width: 550px;
  height: 470px;
  background: url(../img/apartment.jpg) center center;
  background-size: cover;
}
@media screen and (max-width: 1700px) {
  .cleaning-apartment .main-screen__offer {
    left: 8%;
  }
  .cleaning-apartment .main-screen__elipse {
    right: 8%;
  }
}
@media screen and (max-width: 1400px) {
  .cleaning-apartment .main-screen__offer {
    left: 3%;
  }
  .cleaning-apartment .main-screen__elipse {
    right: 3%;
  }
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-wrap {
    width: 500px;
    height: 427px;
  }
  .cleaning-apartment .main-screen__elipse {
    top: 22%;
  }
  .cleaning-apartment .main-screen__elipse-circle {
    width: 180px;
    height: 180px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1250px) {
  .cleaning-apartment .main-screen__title {
    font-size: 35px;
  }
  .cleaning-apartment .main-screen__offer {
    top: 20%;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li {
    line-height: 30px;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li {
    font-size: 28px;
  }
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-wrap {
    width: 450px;
    height: 385px;
  }
  .cleaning-apartment .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -100px;
  }
  .cleaning-apartment .main-screen__elipse-circle-sub-circle {
    left: -110px;
  }
}
@media screen and (max-width: 1100px) {
  .cleaning-apartment .main-screen__offer {
    max-width: 400px;
  }
  .cleaning-apartment .main-screen__title {
    font-size: 28px;
    padding-top: 10px;
  }
  .cleaning-apartment .main-screen__offer {
    top: 20%;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li {
    font-size: 20px;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li {
    line-height: 20px;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 20px;
  }
  .cleaning-apartment .main-screen__elipse {
    top: 22%;
  }
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-wrap {
    width: 300px;
    height: 256px;
  }
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -110px;
  }
  .cleaning-apartment .main-screen__elipse-circle-sub-circle {
    left: -120px;
  }
}
@media screen and (max-width: 900px) {
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-wrap {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 700px) {
  .cleaning-apartment {
    padding-right: 10px;
    padding-left: 10px;
  }
  .cleaning-apartment .main-screen__title {
    font-size: 26px;
  }
  .cleaning-apartment .main-screen__offer {
    position: relative;
    margin: 10px auto;
    left: 0%;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 20px;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
  }
  .cleaning-apartment .main-screen__elipse {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .cleaning-apartment .main-screen__elipse .main-screen__elipse-wrap {
    margin-left: 200px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .cleaning-apartment .main-screen__title {
    font-size: 20px;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li {
    font-size: 18px;
  }
  .cleaning-apartment .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 18px;
  }
}
/* блок со стоимостью уборки квартир */
.price-cleaning-apartment {
  margin-top: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
@media screen and (max-width: 1100px) {
  .price-cleaning-apartment .section-title {
    font-size: 28px;
  }
  .price-cleaning-apartment .price-cleaning__table tr td {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .price-cleaning-apartment .section-title {
    font-size: 24px;
  }
  .price-cleaning-apartment .price-cleaning__table caption {
    font-size: 18px;
  }
  .price-cleaning-apartment .price-cleaning__table tr td {
    font-size: 14px;
  }
}
@media screen and (max-width: 570px) {
  .price-cleaning-apartment .price-cleaning__table tr td {
    font-size: 10px;
  }
}
@media screen and (max-width: 480px) {
  .price-cleaning-apartment {
    padding-right: 5px;
    padding-left: 5px;
  }
  .price-cleaning-apartment .price-cleaning__table tr td {
    font-size: 8px;
  }
  .price-cleaning-apartment {
    overflow-x: scroll;
  }
}
/* блок с названиями городов уборки квартир ========================== */
.town-list {
  margin-top: 20px;
}
.town-list-flex {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.town-list-flex h1 {
  padding: 10px;
  font-size: 26px;
}
@media screen and (max-width: 1400px) {
  .town-list-flex h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1000px) {
  .town-list-flex h1 {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .town-list-flex h1 {
    font-size: 10px;
  }
  .town-list {
    margin-top: 5px;
  }
}
@media screen and (max-width: 480px) {
  .town-list-flex h1 {
    font-size: 8px;
  }
}
/* СТРАНИЦА "Мытье окон" ============================================================ страница "Мытье окон" */
.window-cleaning {
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(../img/main-screen-window-cleaning.jpg) no-repeat;
  background-size: cover;
}
.window-cleaning .main-screen__offer {
  position: absolute;
  top: 13%;
  max-width: 550px;
  min-height: 305px;
}
.window-cleaning .main-screen__offer-text > ul > li {
  line-height: 40px;
  font-style: italic;
}
.window-cleaning .main-screen__offer-text > ul > li:not(:first-child) {
  margin-top: 15px;
}
.window-cleaning .main-screen__elipse {
  top: 14%;
}
.window-cleaning .main-screen__elipse-wrap {
  position: relative;
  width: 550px;
  height: 470px;
  background: url(../img/window.jpg) center center;
  background-size: cover;
}
@media screen and (max-width: 1700px) {
  .window-cleaning .main-screen__offer {
    left: 8%;
  }
  .window-cleaning .main-screen__elipse {
    right: 8%;
  }
}
@media screen and (max-width: 1400px) {
  .window-cleaning .main-screen__offer {
    left: 3%;
  }
  .window-cleaning .main-screen__elipse {
    right: 3%;
  }
  .window-cleaning .main-screen__elipse .main-screen__elipse-wrap {
    width: 500px;
    height: 427px;
  }
  .window-cleaning .main-screen__elipse {
    top: 22%;
  }
  .window-cleaning .main-screen__elipse-circle {
    width: 180px;
    height: 180px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1250px) {
  .window-cleaning .main-screen__title {
    font-size: 35px;
  }
  .window-cleaning .main-screen__offer {
    top: 20%;
  }
  .window-cleaning .main-screen__offer-text > ul > li {
    line-height: 30px;
  }
  .window-cleaning .main-screen__offer-text > ul > li {
    font-size: 28px;
  }
  .window-cleaning .main-screen__elipse .main-screen__elipse-wrap {
    width: 450px;
    height: 385px;
  }
  .window-cleaning .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -100px;
  }
  .window-cleaning .main-screen__elipse-circle-sub-circle {
    left: -110px;
  }
}
@media screen and (max-width: 1100px) {
  .window-cleaning .main-screen__offer {
    max-width: 380px;
  }
  .window-cleaning .main-screen__title {
    font-size: 28px;
    padding-top: 10px;
  }
  .window-cleaning .main-screen__offer {
    top: 15%;
  }
  .window-cleaning .main-screen__offer-text > ul > li {
    font-size: 20px;
  }
  .window-cleaning .main-screen__offer-text > ul > li {
    line-height: 20px;
  }
  .window-cleaning .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 20px;
  }
  .window-cleaning .main-screen__elipse {
    top: 22%;
  }
  .window-cleaning .main-screen__elipse .main-screen__elipse-wrap {
    width: 300px;
    height: 256px;
  }
  .window-cleaning .main-screen__elipse .main-screen__elipse-circle {
    width: 150px;
    height: 150px;
    font-size: 12px;
    left: -110px;
  }
  .window-cleaning .main-screen__elipse-circle-sub-circle {
    left: -120px;
  }
}
@media screen and (max-width: 900px) {
  .window-cleaning .main-screen__elipse .main-screen__elipse-wrap {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 700px) {
  .window-cleaning {
    padding-right: 10px;
    padding-left: 10px;
  }
  .window-cleaning .main-screen__offer {
    max-width: 400px;
  }
  .window-cleaning .main-screen__title {
    font-size: 26px;
  }
  .window-cleaning .main-screen__offer {
    position: relative;
    margin: 10px auto;
    left: 0%;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 20px;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
  }
  .window-cleaning .main-screen__elipse {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .window-cleaning .main-screen__elipse .main-screen__elipse-wrap {
    margin-left: 200px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .window-cleaning .main-screen__title {
    font-size: 20px;
  }
  .window-cleaning .main-screen__offer-text > ul > li {
    font-size: 18px;
  }
  .window-cleaning .main-screen__offer-text > ul > li:not(:first-child) {
    margin-top: 18px;
  }
}
/* блок с ценами на мытье окон ===================================*/
.price-cleaning-window {
  margin-top: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
.price-cleaning__table-footnote {
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.2;
  font-size: 14px;
  padding: 10px;
}
@media screen and (max-width: 1100px) {
  .price-cleaning-window .section-title {
    font-size: 28px;
  }
  .price-cleaning-window .price-cleaning__table tr td {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .price-cleaning-window .section-title {
    font-size: 24px;
  }
  .price-cleaning-window .price-cleaning__table caption {
    font-size: 18px;
  }
  .price-cleaning-window .price-cleaning__table tr td {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .price-cleaning-window {
    padding-right: 5px;
    padding-left: 5px;
  }
  .price-cleaning__table-footnote {
    font-size: 10px;
  }
}
/* СТРАНИЦА "Цены" ================================================================================= страница "Цены" */
.tseny-cleaning-description {
  padding-right: 10px;
  padding-left: 10px;
}
.tseny-cleaning-description .section-title {
  padding-top: 10px;
}
.tseny-cleaning-description hr {
  color: var(--color_header);
  background-color: var(--color_header);
  height: 4px;
  width: 550px;
  text-align: center;
}
.cleaning-tseny__addition {
  max-width: 1100px;
  text-align: justify;
  line-height: normal;
  margin: 0 auto;
}
.cleaning-tseny__addition::first-letter {
  float: left;
  line-height: 35px;
  font-size: 40px;
  font-family: Montserrat;
  color: var(--color_green);
  border: solid var(--color_green);
  padding: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.cleaning-tseny__sale {
  max-width: 1100px;
  margin: 20px auto 20px;
  text-align: center;
  line-height: 1.2;
  font-size: 20px;
}
.cleaning-tseny__sale span {
  letter-spacing: 2px;
  background-color: #ec475b;
  color: #fff;
  display: block;
  margin: 10px auto;
  width: fit-content;
  padding: 5px;
}
.cleaning-tseny__btn {
  color: #fff;
  background-color: #19652A;
  padding: 12px;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.cleaning-tseny__btn:hover {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}
.cleaning-tseny__additional-services {
  max-width: 1000px;
  margin: 0 auto;
}
.cleaning-tseny__additional-services > div {
  font-size: 28px;
}
@media screen and (max-width: 1400px) {
  .tseny-cleaning-description .section-title {
    font-size: 30px;
  }
  .price-cleaning-cottage .section-title {
    font-size: 30px;
  }
  .price-cleaning-apartment .section-title {
    font-size: 30px;
  }
  .price-cleaning-window .section-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .tseny-cleaning-description .section-title {
    font-size: 28px;
  }
  .price-cleaning-cottage .section-title {
    font-size: 28px;
  }
  .price-cleaning-apartment .section-title {
    font-size: 28px;
  }
  .price-cleaning-window .section-title {
    font-size: 28px;
  }
  .cleaning-tseny__addition {
    font-size: 16px;
  }
  .cleaning-tseny__sale {
    font-size: 18px;
  }
  .price-cleaning__table caption {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .tseny-cleaning-description .section-title {
    font-size: 26px;
  }
  .price-cleaning-cottage .section-title {
    font-size: 26px;
  }
  .price-cleaning-apartment .section-title {
    font-size: 26px;
  }
  .price-cleaning-window .section-title {
    font-size: 26px;
  }
  .cleaning-tseny__additional-services .section-title {
    font-size: 26px;
    text-align: left;
    padding-left: 10px;
  }
  .cleaning-tseny__additional-services .price-cleaning__table {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .tseny-cleaning-description .section-title {
    font-size: 24px;
  }
  .price-cleaning-cottage .section-title {
    font-size: 24px;
  }
  .price-cleaning-apartment .section-title {
    font-size: 24px;
  }
  .price-cleaning-window .section-title {
    font-size: 24px;
  }
  .cleaning-tseny__additional-services .section-title {
    font-size: 24px;
  }
  .cleaning-tseny__addition {
    font-size: 14px;
  }
  .cleaning-tseny__sale {
    font-size: 16px;
  }
  .price-cleaning__table caption {
    font-size: 16px;
  }
  .cleaning-tseny__btn {
    font-size: 14px;
  }
}
/* СТРАНИЦА "Контакты" ============================================================================ страница "Контакты" */
.cleaning-company-contact {
  padding-right: 10px;
  padding-left: 10px;
}
.cleaning-company-contact .section-title {
  padding-top: 30px;
}
.cleaning-company-contact hr {
  color: var(--color_header);
  background-color: var(--color_header);
  height: 4px;
  width: 600px;
  text-align: center;
}
.cleaning-company-contact-advice {
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
}
.our-contacts {
  text-align: center;
}
.our-contacts__title {
  font-size: 35px;
}
.our-contacts__text {
  font-size: 26px;
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.4;
}
@media screen and (max-width: 1400px) {
  .cleaning-company-contact .section-title {
    font-size: 30px;
  }
  .our-contacts .our-contacts__title {
    font-size: 30px;
  }
  .our-contacts .our-contacts__text {
    font-size: 24px;
  }
}
@media screen and (max-width: 1100px) {
  .cleaning-company-contact .section-title {
    font-size: 28px;
  }
  .our-contacts .our-contacts__title {
    font-size: 28px;
  }
  .our-contacts .our-contacts__text {
    font-size: 22px;
  }
  .cleaning-company-contact .cleaning-company-contact-advice {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .cleaning-company-contact .section-title {
    font-size: 26px;
  }
  .our-contacts .our-contacts__title {
    font-size: 26px;
  }
  .our-contacts .our-contacts__text {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .cleaning-company-contact .section-title {
    font-size: 24px;
  }
  .our-contacts .our-contacts__title {
    font-size: 24px;
  }
  .our-contacts .our-contacts__text {
    font-size: 18px;
  }
  .cleaning-company-contact .cleaning-company-contact-advice {
    text-align: justify;
  }
}
/* footer ================================================================================================== footer */
footer {
  background-color: var(--color_footer);
  color: #fff;
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footerlogo {
  background: url(../img/logo.png);
  background-size: cover;
  width: 60px;
  height: 60px;
  transition: 0.5s;
}
.footerlogo:hover {
  transform: scale(1.2);
}
.footercenter {
  text-align: center;
  margin: auto 0;
}
.footerleft {
  justify-self: end;
  margin: auto 0;
}
/* max-width: 1400px ================================================================= max-width: 1400px */
@media screen and (max-width: 1400px) {
  .tocall {
    font-size: 18px;
  }
  .main__grid-list {
    font-size: 26px;
  }
  .main__grid-list p {
    line-height: 1.6;
  }
}
/* min-width: 1000px ================================================================= min-width: 1000px */
@media screen and (min-width: 1001px) {
  .menu__list {
    display: flex;
    align-items: center;
  }
  .menu__list > li {
    padding: 10px 0px;
  }
  .menu__sub-list {
    transform: translate(0px, 10%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s ease 0s;
  }
}
/* max-width: 1000px ================================================================= max-width: 1000px */
@media screen and (max-width: 1000px) {
  .tocall {
    display: none;
  }
  .header__phone {
    display: flex;
  }
  .menu__icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
  }
  .menu__icon span,
  .menu__icon::before,
  .menu__icon::after {
    left: 0;
    position: absolute;
    height: 10%;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: #fff;
  }
  .menu__icon::before,
  .menu__icon::after {
    content: "";
  }
  .menu__icon::before {
    top: 0;
  }
  .menu__icon::after {
    bottom: 0;
  }
  .menu__icon span {
    top: 50%;
    transform: scale(1) translate(0px, -50%);
  }
  .menu__icon._active span {
    transform: scale(0) translate(0px, -50%);
  }
  .menu__icon._active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0px, -50%);
  }
  .menu__icon._active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0px, 50%);
  }
  .menu__body {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 100px 30px 30px;
    transition: left 0.3s ease 0s;
    overflow: auto;
  }
  .menu__body._active {
    left: 0;
  }
  .menu__body::before {
    content: "";
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 80px;
    background-color: var(--color_green);
    z-index: 2;
  }
  .menu__list > li {
    flex-wrap: wrap;
    margin: 0px 0px 30px 0px;
  }
  .menu__list > li:last-child {
    margin-bottom: 0px;
  }
  .menu__list > li._active .menu__sub-list {
    display: block;
  }
  .menu__link {
    font-size: 24px;
  }
  .menu__sub-list {
    position: relative;
    top: 0;
    background-color: #fff;
    flex: 1 1 100%;
    margin: 20px 0px 0px 0px;
    display: none;
  }
  .menu__sub-link {
    font-size: 20px;
    color: #000;
  }
}
