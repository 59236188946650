/* basic */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* меняются для каждой формы*/
/* для form1 - формы в header-е "У вас есть вопросы?" ================================== form1 */
.main-screen-right-form {
  margin-top: 140px;
}
.form1__wrapper {
  max-width: 70%;
  margin: 0 auto;
}
.form1-title {
  text-align: center;
  font-size: 24px;
  color: #fff;
}
.form1-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-direction: column;
  flex-wrap: wrap;
}
.form1-row .form-submit {
  margin-top: 30px;
}
.form1-row .form-submit button {
  width: 100%;
  font-size: 24px;
}
.form1-group {
  margin-top: 25px;
}
.form1-group .form-control {
  height: 40px;
}
.form1-group.form-agreement {
  color: #fff;
}
.form1-group.form-agreement .custom-control-label {
  font-size: 1.25rem;
}
.form1-dialog {
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: 10px;
}
.listform1 {
  margin-top: 10px;
}
.form-contact-you {
  color: #fff;
  margin-top: 20px;
  padding: 0 30px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .form1-row > .form1-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 100%;
  }
}
/* стили для формы form2 на главной странице */
.form2-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form2-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form2-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form2__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form2-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form2-group {
  margin-bottom: 1rem;
}
.form2-group .form-control {
  height: 40px;
}
@media (min-width: 576px) {
  .form2-row > .form2-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form2 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form2 {
    padding-right: 10px;
    padding-left: 10px;
  }
}
/* для form3 внизу страницы "Уборка коттеджей"========================================================================================== formcottage2*/
.form3-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form3-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form3-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form3__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form3-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form3-group {
  margin-bottom: 1rem;
}
.form3-group .form-control {
  height: 40px;
}
@media (min-width: 576px) {
  .form3-row > .form3-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form3 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form3-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .form3-title {
    font-size: 1.3rem;
  }
  .form3-subtitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .form3-row > .form3-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .form3-title {
    font-size: 1.2rem;
  }
  .form3-subtitle {
    font-size: 12px;
  }
}
/* END для form3  =================================================================== END form3 */
/* стили для формы form4 на странице "Уборка после ремонта ==================================== */
.form4-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form4-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form4-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form4__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form4-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form4-group {
  margin-bottom: 1rem;
}
.form4-group .form-control {
  height: 40px;
}
@media (min-width: 576px) {
  .form4-row > .form4-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form4 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form4-container {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .form4-title {
    font-size: 1.3rem;
  }
  .form4-subtitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .form4-row > .form4-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .form4-title {
    font-size: 1.2rem;
  }
  .form4-subtitle {
    font-size: 12px;
  }
}
/* END form4 ================================================================================== END form4 */
/* стили для form5 на странице "Уборка квартир" ================================================ form5  */
.form5-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form5-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form5-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form5__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form5-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form5-group {
  margin-bottom: 1rem;
}
.form5-group .form-control {
  height: 40px;
}
@media (min-width: 576px) {
  .form5-row > .form5-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form5 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form5-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .form5-title {
    font-size: 1.3rem;
  }
  .form5-subtitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .form5-row > .form5-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .form5-title {
    font-size: 1.2rem;
  }
  .form5-subtitle {
    font-size: 12px;
  }
}
/* END form5 =============================================================================== END form5  */
/* стили для form6 на странице "Мытье окон" ================================================ form6  */
.form6-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form6-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form6-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form6__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form6-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form6-group {
  margin-bottom: 1rem;
}
.form6-group .form-control {
  height: 40px;
}
@media (min-width: 576px) {
  .form6-row > .form6-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form6 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form6-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .form6-title {
    font-size: 1.3rem;
  }
  .form6-subtitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .form6-row > .form6-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .form6-title {
    font-size: 1.2rem;
  }
  .form6-subtitle {
    font-size: 12px;
  }
}
/* END form6 =============================================================================== END form6  */
/* стили для form10 на странице "Контакты" ==================================================== form10  */
.form10-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin: 40px auto;
  max-width: 800px;
  background-color: #7591F8;
  color: #fff;
  border-radius: 10px;
}
.form10-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}
.form10-subtitle {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
}
.form10__wrapper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.form10-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form10-group {
  margin-bottom: 1rem;
}
.form10-group .form-control {
  height: 40px;
  margin-top: 5px;
}
.form10-group-full-width .form-control {
  height: 80px;
}
@media (min-width: 576px) {
  .form10-row > .form10-group {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media (min-width: 576px) {
  .form10-row > .form10-group-full-width {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
#feedback-form10 button {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .form10-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .form10-title {
    font-size: 1.3rem;
  }
  .form10-subtitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .form10-row > .form10-group {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .form10-title {
    font-size: 1.2rem;
  }
  .form10-subtitle {
    font-size: 12px;
  }
}
/* END form10 =============================================================================== END form10  */
.d-none {
  display: none !important;
}
label {
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
.form-control {
  display: block;
  width: 100%;
  height: 25px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
textarea.form-control {
  overflow: auto;
  resize: vertical;
  height: auto;
}
/* Стили для секции, с помощью которой можно добавить к форме файлы */
.form-attachments__wrapper {
  position: relative;
  background: #fff;
  border: 3px dashed #ccc;
  border-radius: 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.form-attachments__wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: pointer;
  display: block;
}
.form-attachments__description {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px;
}
.form-attachments__description > :first-child {
  font-weight: 700;
}
.form-attachments__description > :last-child {
  color: #6c757d;
  font-size: 0.8125rem;
}
.form-attachments__items {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
}
.form-attachments__item {
  flex: 0 0 25%;
  overflow: hidden;
  padding: 4px;
  font-size: 0.75rem;
}
.form-attachments__item-wrapper {
  border: 1px solid #e0e0e0;
  background: #f5f5f5;
  padding: 26px 4px;
  border-radius: 4px;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-attachments__item-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}
.form-attachments__item-name {
  margin-top: auto;
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}
.form-attachments__item-size {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3px 6px;
  font-weight: bold;
  text-align: right;
}
.form-attachments__item-link {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.375rem 0.5rem;
  color: inherit;
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.form-attachments__item.is-valid .form-attachments__item-wrapper {
  border-color: #28a745;
  background-color: #f8fcf9;
}
.form-attachments__item.is-invalid .form-attachments__item-wrapper {
  border-color: #dc3545;
  background-color: #fefbfb;
}
/* Стили секции, в которой выводится капча */
.form-captcha {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.form-captcha__image {
  border-radius: 4px;
  margin-right: 0.5rem;
}
.form-captcha__refresh {
  padding: 0.5rem 0.75rem;
  line-height: 1;
  border: 1px solid #ced4da;
  margin-right: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}
.form-captcha__refresh:hover {
  background-color: #eee;
}
.form-captcha__input {
  flex-grow: 1;
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 400px) {
  .form-captcha {
    flex-wrap: wrap;
  }
  .form-captcha__input {
    min-width: 200px;
    margin-top: 0.5rem;
  }
}
.form-captcha__input .invalid-feedback {
  position: absolute;
  bottom: -1.5rem;
}
/* CSS для секции "Пользовательское соглашение" */
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.form-agreement label {
  font-size: 0.875rem;
}
.form-agreement {
  font-size: 10px;
  line-height: 160%;
}
/* CSS для индикации полей форме при их валидности */
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
/* CSS для индикации полей форме при их не валидности */
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback {
  display: block;
}
/* CSS для индикатора */
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.form-submit {
  text-align: center;
  margin-top: 10px;
}
/* CSS для кнопки submit */
button[type="submit"] {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.375rem 0.75rem 0.375rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
}
button[type="submit"]::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.333 512'%3E%3Cpath fill='%23fff' d='M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z'/%3E%3C/svg%3E") transparent no-repeat center center;
  background-size: 100% 100%;
}
button[type="submit"]:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
button[type="submit"]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
button[type="submit"]:disabled {
  opacity: 0.65;
}
/* Стили для сообщения об ошибках */
.form-error {
  position: relative;
  padding: 0.5rem 1rem 0.6rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  color: #dc3545;
  background-color: #dc35450d;
  border: 1px solid #dc3545;
}
/* Стили для сообщения об успешной отправки */
.form-result-success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.25rem;
  border-radius: 4px;
}
.form-result-success > div {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #eff4f1;
  z-index: 1001;
  border-radius: 0;
  color: #28a745;
  background-color: #eff4f1;
}
.form-result-success a {
  color: #28a745;
  background-color: transparent;
  font-weight: 700;
}
/* стили popup forms =========================================================================== стили popup forms */
.custom-file-label {
  overflow: hidden;
}
.custom-file-label::after {
  content: "Найти";
}
.panel-success {
  border-color: #29b74e;
}
.panel-success > .panel-heading {
  color: #fff;
  background-color: #2ecc71;
  border-color: #29b74e;
}
.btn-success {
  color: #fff;
  background-color: #2ecc71;
  border-color: #29b765;
}
.success {
  color: #29b74e;
}
.jgrowl-error {
  background-color: #a94442 !important;
}
.jgrowl-success {
  background-color: #3c763d !important;
}
.jgrowl-warning {
  background-color: #8a6d3b !important;
}
