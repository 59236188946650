@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Light.ttf');//font-weight: 300
    font-weight: 300;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-LightItalic.ttf');//font-weight: 300
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Regular.ttf');//font-weight: 400
    font-weight: 400;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Regular.ttf');//font-weight: 400
    font-weight: 400;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Italic.ttf');//font-weight: 400
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Medium.ttf');//font-weight: 500
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-MediumItalic');//font-weight: 500
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-SemiBold.ttf');//font-weight: 600
    font-weight: 600;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-SemiBoldItalic.ttf');//font-weight: 600
    font-style: italic;
    font-weight: 600;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Bold.ttf');//font-weight: 700
    font-weight: 700;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-BoldItalic.ttf');//font-weight: 700
    font-style: italic;
    font-weight: 700;
}
@font-face {
    font-family: 'pixflow-font-library';
    src: url('/fonts/pixflow-font-library.woff');
    font-style: normal;
}
